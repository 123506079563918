<template>
  <div class="data-list-container">
    <vs-tabs class="custom-tab">
      <vs-tab label="ฟังก์ชัน">
        <HomeFunction />
      </vs-tab>
      <vs-tab label="ลักษณะพิเศษของบ้าน">
        <HomeFeature />
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import HomeFunction from './components/HomeFunction'
import HomeFeature from './components/HomeFeature'
export default {
  components: {
    HomeFunction,
    HomeFeature,
  },
}
</script>

<style lang="scss">
  .custom-tab {
    z-index: 1;
  }
</style>
