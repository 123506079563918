<template>
  <div id="lh-table">
    <PopupLanguage
      v-model="isPopupDuplicateActive"
      :hidden-lang="hiddenLang"
      :on-click-btn="handlerDuplicateContent"
      name="property-asset-project-create"
    />
    <custom-table 
      ref="table" 
      :max-items="itemsPerPage" 
      :data="items" 
      :searchKeys="searchKeys"
      pagination 
      search>
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <AddNewHomeFunction
          :allowLangs="['th']"
          :on-click="onOpenAddPopup"
          :show-popup="false"
          name="home-function"
          button-label="เพิ่มฟังก์ชันบ้าน"
        />
        <LhDropdownPerPage 
          :items="items" 
          :items-per-page="itemsPerPage" 
          :current-page="currentPage" 
          :queried-items="queriedItems"
          @click="(page) => itemsPerPage = page" /> 
      </div>
      <template slot="thead">
        <vs-th sort-key="house_function_title">ชื่อฟังก์ชัน</vs-th>
        <vs-th sort-key="house_function_unit">หน่วยของฟังก์ชัน</vs-th>
        <vs-th sort-key="is_required">Type</vs-th>
        <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
        <vs-th sort-key="created_at">วันที่สร้าง</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr 
            v-for="(tr, indextr) in data" 
            :data="tr" 
            :key="indextr">
            <td class="td custom-table--td" @click.stop="updateData(tr)">
              {{ tr.house_function_title }}
            </td>
            <td class="td custom-table--td" @click.stop="updateData(tr)">{{ tr.house_function_unit }}</td>
            <td class="td custom-table--td" @click.stop="updateData(tr)">
              <vs-chip
                v-if="tr.is_required" color="danger">
                  Fix
              </vs-chip>
            </td>
            <td class="td custom-table--td" @click.stop="updateData(tr)">{{ updatedAtDatetimeFormat(tr.updated_at) }}</td>
            <td class="td custom-table--td" @click.stop="updateData(tr)">{{ updatedAtDatetimeFormat(tr.created_at) }}</td>
            <vs-td class="whitespace-no-wrap cursor-default">
              <feather-icon
                class="mr-2 cursor-pointer"
                icon="CopyIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="duplicateContent(tr)"
              />
              <feather-icon
                class="cursor-pointer"
                v-if="!tr.is_required || isSuperEditor"
                icon="EditIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="updateData(tr)"
              />
              <feather-icon
                v-if="!tr.is_required || isSuperEditor"
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2 cursor-pointer"
                @click.stop="deleteData(tr.uuid)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </custom-table>
  </div>
</template>

<script>
/* eslint-disable */
import { computed, ref } from '@vue/composition-api'
import filter from 'lodash/filter'
import map from 'lodash/map'
import get from 'lodash/get'
import find from 'lodash/find'
import omit from 'lodash/omit'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'
import useDatepicker from '@/use/useDatepicker'
import AddNewHomeFunction from '@/components/AddNewContent'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'
import PopupLanguage from '@/components/PopupLanguage'

export default {
  components: { AddNewHomeFunction, LhDropdownPerPage, CustomTable, PopupLanguage },
  props: {
    onOpenAddPopup: Function,
  },
  setup(props, ctx) {
    const itemsPerPage = ref(10)
    const crudFunction = useCrud(ctx, 'houseFunction')
    const notifyFn = useNotify(ctx)
    const { moment, updatedAtDatetimeFormat } = useDatepicker()
    crudFunction.routePrefix.value = 'property-plan-home-function'

    const items = computed(() => {
      return map(crudFunction.result.value, (item) => ({
        ...item,
      }))
    })

    const hiddenLang = ref([])

    const updateData = (houseData) => {
      props.onOpenAddPopup(houseData)
    }

    const isSuperEditor = computed(() => {
      return ctx.root.$acl.check('super_editor')
    })

    const handlerDuplicateContent = async (lang) => { 
      const value = crudFunction.contentDuplicateId.value
      await ctx.root.$vs.loading()

      const activeUser = ctx.root.$store.state.AppActiveUser
      const email = get(activeUser, 'email', '')
      let created_by = value.created_by
      // add user created by
      if (!value.created_by) {
        created_by = email
      }
      const updated_by = email
      value.lang = lang

      return ctx.root.$store
        .dispatch('houseFunction/addItem', {
          ...omit(value, ['updated_at', 'created_at']),
          lang,
          created_by,
          updated_by
        })
        .then(async (uuid) => {
          notifyFn.success({ text:'เพิ่มข้อมูลเรียบร้อย' })
          await ctx.root.$store.dispatch('locale/changeLang', lang)
          const data = await ctx.root.$store.dispatch('houseFunction/fetchOne', uuid)
          props.onOpenAddPopup({...data, ...data[lang]})
          await ctx.root.$vs.loading.close()
        })
        .catch(async (error) => {
          await ctx.root.$vs.loading.close()
          notifyFn.error({ text: JSON.stringify(error) })
        })
    }

    const duplicateContent = async (value) => {
      const existLang = []

      const result = await ctx.root.$store.dispatch(
        'houseFunction/fetchById',
        value.id,
      )

      hiddenLang.value = result
      crudFunction.duplicateContent(value)
    }

    return {
      ...crudFunction,
      itemsPerPage,
      items,
      duplicateContent,
      updatedAtDatetimeFormat,
      handlerDuplicateContent,
      updateData,
      isSuperEditor,
      hiddenLang,
      searchKeys: ['house_function_title', 'house_function_unit']
    }
  },
}
</script>
