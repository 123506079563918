<template>
  <div>
    <vs-popup
      :active.sync="active"
      :title="`เพิ่มฟังก์ลักษณะพิเศษของบ้าน (ภาษา ${$store.getters['locale/getLabel']})`"
      class="add_popup"
    >
      <Form 
        :onCreateSuccess="onCreateSuccess" 
        :editHouseData="editHouseData"
        :popup-active="active" />
    </vs-popup>
    <Table :onOpenAddPopup="onOpenAddPopup" />
  </div>
</template>

<script>
import Table from './Table'
import Form from './Form'
import { ref } from '@vue/composition-api'

export default {
  components: {
    Table,
    Form,
  },
  setup() {
    const active = ref(false)
    const editHouseData = ref({})

    const onOpenAddPopup = (houseData) => {
      active.value = !active.value
      if (typeof houseData === 'object') {
        editHouseData.value = {
          ...houseData
        }
      } else {
        editHouseData.value = {}
      }
    }
    const onCreateSuccess = () => {
      active.value = false
    }

    return {
      active,
      onOpenAddPopup,
      onCreateSuccess,
      editHouseData,
    }
  },
}
</script>
