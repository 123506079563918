<template>
  <div>
    <vs-popup 
      :active.sync="active" 
      :title="`เพิ่มฟังก์ชันบ้าน (ภาษา ${$store.getters['locale/getLabel']})`" 
      class="add_popup">
      <Form 
        :onCreateSuccess="onCreateSuccess" 
        :editHouseData="editHouseData"
        :popup-active="active" />
    </vs-popup>
    <Table :onOpenAddPopup="onOpenAddPopup" />
  </div>
</template>

<script>
import Table from './Table'
import Form from './Form'
import { ref } from '@vue/composition-api'
import get from 'lodash/get'

export default {
  components: {
    Table,
    Form,
  },
  setup(props, ctx) {
    const active = ref(false)
    const editHouseData = ref({})

    const onOpenAddPopup = (houseData) => {
      active.value = !active.value
      if (typeof houseData === 'object') {
        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let created_by = houseData.created_by
        // add user created by
        if (!houseData.created_by) {
          created_by = email
        }
        const updated_by = email

        editHouseData.value = {
          ...houseData,
          created_by,
          updated_by
        }
      }
    }
    const onCreateSuccess = () => {
      active.value = false
    }

    return {
      active,
      onOpenAddPopup,
      onCreateSuccess,
      editHouseData,
    }
  },
}
</script>
