<template>
  <div>
    <ValidationObserver 
      v-slot:default="context" 
      ref="form">
      <div class="vx-row">
        <div class="vx-col w-full">
          <form @submit.prevent="submit(context)">
            <FormLayout>
              <FormLabel>
                ชื่อฟังก์ชัน*
              </FormLabel>
              <FormContent class="flex flex-col md:flex-row md:space-x-3 md:w-9/12">
                <div class="w-full md:w-8/12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="ชื่อฟังก์ชัน"
                    rules="required"
                  >
                    <vs-input
                      v-model="formData.house_function_title"
                      class="w-full"
                      name="house_function_title"
                      placeholder="ชื่อฟังก์ชัน"
                    />
                    <span 
                      v-if="errors.length > 0" 
                      class="text-danger text-sm">{{
                        errors[0]
                      }}</span>
                  </ValidationProvider>
                </div>
                <div class="w-full md:w-4/12">
                  เช่น ห้องรับแขก, ห้องทำงาน, ที่จอดรถ
                </div>
              </FormContent>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                ระบุหน่วยฟังก์ชัน*
              </FormLabel>
              <FormContent class="flex flex-col md:flex-row md:items-center md:space-x-3 md:w-9/12">
                <div class="w-full md:w-8/12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="ระบุหน่วยฟังก์ชัน*"
                    rules="required"
                  >
                    <vs-input
                      v-model="formData.house_function_unit"
                      class="w-full"
                      name="house_function_title"
                      placeholder="ระบุหน่วยฟังก์ชัน"
                    />
                    <span 
                      v-if="errors.length > 0" 
                      class="text-danger text-sm">{{
                        errors[0]
                      }}</span>
                  </ValidationProvider>
                </div>
                <div class="w-full md:w-4/12">เช่น ห้อง, คัน </div>
              </FormContent>
            </FormLayout>
            <FormLayout v-if="isSuperEditor">
              <FormLabel>
                กำหนดให้ไม่สามารถลบได้
              </FormLabel>
              <FormContent>
                <vs-checkbox v-model="formData.is_required" />
              </FormContent>
            </FormLayout>
            <vs-divider />
            <FormLayout>
              <FormLabel>
                <span>แก้ไขล่าสุดโดย</span>
              </FormLabel>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <span class="mt-2"> {{formData.updated_by}}</span>
              </div>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                <span>แก้ไขล่าสุดวันที่</span>
              </FormLabel>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
              </div>
            </FormLayout>
            <FormLayout class="mt-8">
              <FormLabel/>
              <FormContent>
                <vs-button 
                  @click="submit(context)">
                  {{
                    formData.id ? 'แก้ไข' : 'เพิ่ม' 
                  }}
                </vs-button>
              </FormContent>
            </FormLayout>
          </form>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { reactive, watch, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import get from 'lodash/get'
import find from 'lodash/find'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'

export default {
  name: 'Form',
  components: {
    ValidationObserver,
  },
  props: {
    onCreateSuccess: {
      type: Function,
      default: () => {}
    },
    editHouseData: {
      type: Object,
      default: () => ({})
    },
    popupActive: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const notifyFn = useNotify(ctx)
    const initialFormData = computed(() => {
      return {
        uuid: props.editHouseData.uuid,
        id: props.editHouseData.id,
        lang: props.editHouseData.lang,
        house_function_title: props.editHouseData.house_function_title || '',
        house_function_unit: props.editHouseData.house_function_unit || '',
        is_required: props.editHouseData.is_required,
        updated_at: props.editHouseData.updated_at,
        created_by: props.editHouseData.created_by,
        updated_by: props.editHouseData.updated_by
      }
    })

    const { addItem, formatDate } = useCrud(ctx, 'houseFunction', {
      fetchOnMount: false,
      onModifyItemSuccess: props.onCreateSuccess,
    })

    const formData = reactive(initialFormData)

    const submit = ({ validate }) => { 
      const existing = find(get(ctx.root.$store.state.houseFunction, 'items', []), (v => {
        return v.house_function_title === formData.value.house_function_title 
          && formData.value.id !== v.id
      }))

      if (existing) {
        return notifyFn.error({
          title: 'แจ้งเตือน',
          text: 'มีฟังก์ชันบ้านนี้อยู่ในระบบแล้ว กรุณาตรวจสอบ'
        }) 
      }

      addItem({ validate }, formData.value)
    }

    const buttonLabel = computed(() => {
      return formData.value.id ? 'แก้ไข' : 'เพิ่ม'
    })
    
    const isSuperEditor = computed(() => {
      return ctx.root.$acl.check('super_editor')
    })

    watch(
      () => props.popupActive,
      (value) => {
        if (!value && ctx.refs.form && ctx.refs.form.reset) {
          ctx.refs.form.reset()
          formData.value.id = null
          formData.value.uuid = null
          formData.value.house_function_title = ''
          formData.value.house_function_unit = ''
          formData.value.is_required = false
          formData.value.updated_at = null
          formData.value.created_by = null
          formData.value.updated_by = null
        }
      }
    )

    return {
      submit,
      formData,
      buttonLabel,
      isSuperEditor,
      formatDate
    }
  },
}
</script>
