<template>
  <div>
    <ValidationObserver 
      v-slot:default="context" 
      ref="form">
      <div class="vx-row">
        <div class="vx-col w-full">
          <form @submit.prevent="submit(context)">
            <FormLayout class="md:items-center pt-0"> 
              <FormLabel>
                ชื่อลักษณะพิเศษ <span class="text-danger"> *</span>
              </FormLabel>
              <FormContent class="flex flex-col">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="ชื่อลักษณะพิเศษ"
                  rules="required"
                >
                  <vs-input
                    v-model="formData.house_function_feature_title"
                    class="w-full"
                    name="house_function_feature_title"
                    placeholder="เช่น ติดตั้งเครื่องปรับอากาศ,ตกแต่งเฟอร์นิเจอร์"
                  />
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">{{
                      errors[0]
                    }}</span>
                </ValidationProvider>
              </FormContent>
            </FormLayout>
            <vs-divider />
            <FormLayout>
              <FormLabel>
                <span>แก้ไขล่าสุดโดย</span>
              </FormLabel>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <span class="mt-2"> {{formData.updated_by}}</span>
              </div>
            </FormLayout>
            <FormLayout>
              <FormLabel>
                <span>แก้ไขล่าสุดวันที่</span>
              </FormLabel>
              <div class="flex items-center vx-col w-full md:w-6/12">
                <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
              </div>
            </FormLayout>
            <FormLayout class="mt-8">
              <FormLabel/>
              <FormContent>
                <vs-button 
                  class="mr-2" 
                  @click="submit(context)">
                  {{
                    buttonLabel
                  }}
                </vs-button>
              </FormContent>
            </FormLayout>
          </form>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { reactive, watch, computed } from '@vue/composition-api'
// import get from 'lodash/get'
// import find from 'lodash/find'
import { ValidationObserver } from 'vee-validate'
import useCrud from '@/use/useCrud'
// import useNotify from '@/use/useNotify'

export default {
  name: 'Form',
  components: {
    ValidationObserver,
  },
  props: {
    onCreateSuccess: {
      type: Function,
      default: () => {}
    },
    editHouseData: {
      type: Object,
      default: () => ({})
    },
    popupActive: {
      type: Boolean,
      required: true
    }
  },
  setup(props, ctx) {
    const initialFormData = computed(() => {
      return {
        uuid: props.editHouseData.uuid,
        id: props.editHouseData.id,
        lang: props.editHouseData.lang,
        house_function_feature_title:
          props.editHouseData.house_function_feature_title || '',
        updated_at: props.editHouseData.updated_at,
        created_by: props.editHouseData.created_by,
        updated_by: props.editHouseData.updated_by,
      }
    })

    const { addItem, formatDate } = useCrud(ctx, 'houseFunctionFeature', {
      fetchOnMount: false,
      onModifyItemSuccess: props.onCreateSuccess,
    })

    const formData = reactive(initialFormData)

    const submit = ({ validate }) => {
      addItem({ validate }, formData.value)
    }

    const buttonLabel = computed(() => {
      return formData.value.id ? 'แก้ไข' : 'เพิ่ม'
    })

    watch(
      () => props.popupActive,
      (value) => {
        if (!value && ctx.refs.form && ctx.refs.form.reset) {
          ctx.refs.form.reset()
          formData.value.house_function_feature_title = ''
          formData.value.updated_at = null
          formData.value.created_by = null
          formData.value.updated_by = null
        }
      }
    )

    return {
      submit,
      formData,
      formatDate,
      buttonLabel,
    }
  },
}
</script>
